@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root{
    --primary-blue: #0052D4;
    --primary-lightblue: #6FB1FC;
    --primary-Purple: #4B0082;
}


::-webkit-scrollbar{
    width: 10px;
    background: #AEC6CF;

}
::-webkit-scrollbar-track{
    background: transparent;
    border-radius: 100vw;
    margin-block: 0.5em;


}

::-webkit-scrollbar-thumb{
    background: #0052D4;
    border-radius: 4px;
    border-radius: 100vw;
    padding-block: 0.5em;
    /* margin: 2px; */
}
::-webkit-scrollbar-thumb:hover{
    background: #002147;
    scale: 2;
}

@layer base{
    *{
        @apply font-[Inter]
    }
    h1{
        @apply text-3xl font-bold
    }
    li{
        /* @apply p-4 */
    }
    button{
        /* @apply bg-[var(--primary-blue)] px-8 py-2 rounded-3xl */
    }
}